<template>
	<div>
	<Navbar/>
	<div class="auth-wrapper auth-v2">
	  <b-row class="auth-inner m-0">
		<!-- Brand logo-->
		<b-link class="brand-logo">
		  <!-- <vuexy-logo /> -->
		</b-link>
		<!-- /Brand logo-->
  
		<!-- Left Text-->
		<b-col
		  lg="6"
		  class="d-none d-lg-flex align-items-center p-5"
		  style="margin-top: 2rem"
		>
		  <div
			class="
			  w-100
			  d-lg-flex
			  align-items-center
			  justify-content-center
			  px-5
			  prime-blue-background
			"
		  >
			<b-img
			  fluid
			  src="../../assets/yicimages/register/HumaaansWireframe2.png"
			  alt="Register V2"
			/>
		  </div>
		</b-col>
		<!-- /Left Text-->
  
		<!-- Register-->
		<b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
		  <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
			<b-card-title title-tag="h2" class="font-weight-bold mb-1">
			  Create Password
			</b-card-title>
  
			<!-- form -->
			<validation-observer ref="passwordForm">
			  <b-form  @submit.stop.prevent class="auth-register-form mt-2">
				<!-- firstname -->
				<!-- <b-form-group label-for="register-password"> -->
				  <password v-model="password" :toggle="true" required placeholder="Enter new password here" class="password"/>
				<!-- </b-form-group> -->
				<!-- captcha -->
				<!-- <div class="captcha">
				  <Captcha @verified="captchaVerified" />
				</div> -->
				<!-- /captcha -->
				<b-button
				variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				  size="lg"
				  style="background: #4385f5;"
				type="submit"
				  @click="validationForm"
				  v-b-modal.modal-1
				>
				  Submit
				</b-button>
			  </b-form>
			</validation-observer>
		  </b-col>
		  <b-modal ok-only id="modal-1">
			<p v-if="password">
			Congratulations! Your password is changed.
			Redirecting to the Login Page in 5 seconds.
			</p>
			<p v-else>
			Please write a valid password!
			</p>
		</b-modal>
		</b-col>
		<!-- /Register-->
	  </b-row>
	  <!-- Virtual address containing Popup! -->
	</div>
</div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations, mapActions } from "vuex";
  import VuexyLogo from "@core/layouts/components/Logo.vue";
  import {
	BRow,
	BCol,
	BModal,
	BLink,
	BButton,
	BForm,
	BFormCheckbox,
	BFormGroup,
	BFormInput,
	BInputGroup,
	BInputGroupAppend,
	BImg,
	BCardTitle,
	BCardText,
  } from "bootstrap-vue";
  import { required, email } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import Password from "vue-password-strength-meter";
  import Navbar from "@/yicpages/navbar/Navbar.vue";
  
  export default {
	components: {
	  VuexyLogo,
	  BRow,
	  BModal,
	  BImg,
	  BCol,
	  BLink,
	  BButton,
	  BForm,
	  BCardText,
	  BCardTitle,
	  BFormCheckbox,
	  BFormGroup,
	  BFormInput,
	  BInputGroup,
	  BInputGroupAppend,
	  // validations
	  ValidationProvider,
	  ValidationObserver,
	  Navbar,
	  Password,
	},
  
	data() {
	  return {
		password: "",
		// modalShow: true,
		modalShow: false,
		// isCaptchaVerified: false,
	  };
	},
	computed: {},
	methods: {
	  ...mapActions({ setPassword: "resetPass/changePass" }),
	  async validationForm() {
		// console.log(this.isCaptchaVerified);
		// if (!this.isCaptchaVerified) return;
		var success = await this.$refs.passwordForm.validate()
		  if (success) {
			await this.setPassword({ password: this.password,token: this.$route.params.token});
			// this.modalShow = true;
			setTimeout(() => {
				this.$router.push('/login')
				
			},5000)
			console.log("njm")

		  }
		

		// this.$refs.checkmail.dummy();
	  },
	  // captchaVerified() {
	  //   this.isCaptchaVerified = true;
	  // },
	},
  };
  /* eslint-disable global-require */
  </script>
  <style>
  .prime-blue-background {
	background-color: #4385f5;
  }
  select option {
	margin: 40px;
	background-color: white;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
  </style>
  <style scoped>
  .password {
	margin-left: 0;
  }
  </style>
  